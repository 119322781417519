/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://jo6dsnzirbdgxb2s5awit3mnja.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-e65l2uay6bgefn2qhsa5xf2ymm",
    "aws_cognito_identity_pool_id": "eu-west-1:f3f265ee-2fe4-46f6-8575-b698600c808a",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_iH53aEVCJ",
    "aws_user_pools_web_client_id": "2n397486luoejf0tuhdphi4nr8",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "andel-connected-cloud-amplify-storage115710-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
